import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class UserleService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getUsers() {
      return axiosService.axiosIns.get(this.jwtConfig.getUsers)
    }

    getUser(id) {
      return axiosService.axiosIns.get(`${this.jwtConfig.getUser}/${id}`)
    }

    deleteUser(id) {
      return axiosService.axiosIns.post(`${this.jwtConfig.deleteUser}/${id}`)
    }

    resetPassword(userData) {
      return axiosService.axiosIns.post(this.jwtConfig.resetPasswordAdmin, { ...userData })
    }

    getUserProfiles(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getUserProfiles, ...args)
    }

    registerUser(userData) {
      return axiosService.axiosIns.post(this.jwtConfig.registerUser, { ...userData })
    }

    updateUser(userData) {
      return axiosService.axiosIns.post(`${this.jwtConfig.updateUser}/${userData.id}`, { ...userData })
    }

    assignUser(assignedClients) {
      return axiosService.axiosIns.post(`${this.jwtConfig.assignUser}`, { ...assignedClients })
    }

    deleteUserProfile(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.deleteUserProfile, ...args)
    }

    editByPass(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.editByPass, ...args)
    }

    getUserSpecialPermissions(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getUserSpecialPermissions, ...args)
    }

    editUserPermission(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.editUserPermission, ...args)
    }
}

function useUserleService() {
  const userService = new UserleService()

  return {
    userService,
  }
}

const { userService } = useUserleService()
export default userService
