<template>
  <!-- modal -->
  <b-modal
    id="reset-password-modal"
    ref="reset-password-modal"
    title="Reset Password"
    ok-title="Reset"
    cancel-variant="outline-secondary"
    @hidden="resetForm"
    @ok="onSubmit"
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- TITLE -->
        <b-row>
          <b-col md="12">
            <validation-provider
              #default="validationContext"
              name="Password"
              rules="required|min:8|password"
            >
              <b-form-group
                label="Password"
                label-for="a-password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="validationContext.errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="a-password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility('passwordFieldType')"
                    />
                  </b-input-group-append>
                </b-input-group>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- TITLE -->
        <b-row>
          <b-col md="12">
            <validation-provider
              #default="validationContext"
              name="Confirm Password"
              rules="required|confirmed:Password"
            >
              <b-form-group
                label="Confirm your new Password"
                label-for="ac-password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="validationContext.errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="ac-password"
                    v-model="passwordConfirm"
                    :type="passwordConfirmationFieldType"
                    class="form-control-merge"
                    :state="getValidationState(validationContext)"
                    trim
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordConfirmationToggleIcon"
                      @click="togglePasswordVisibility('passwordConfirmationFieldType')"
                    />
                  </b-input-group-append>
                </b-input-group>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BFormGroup, BModal, VBModal, BFormInput, BFormInvalidFeedback, BCol, BRow, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, alphaNum, min, confirmed,
} from '@validations'
import store from '@/store'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    BFormInvalidFeedback,
    BCol,
    BRow,
    BInputGroupAppend,
    BInputGroup,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    refreshHandler: {
      type: Function,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      passwordFieldType: 'password',
      passwordConfirmationFieldType: 'password',
      min,
      confirmed,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConfirmationToggleIcon() {
      return this.passwordConfirmationFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordVisibility(field) {
      this[field] = this[field] === 'password' ? 'text' : 'password'
    },
  },
  setup(props) {
    const password = ref(null)
    const passwordConfirm = ref(null)

    const resetData = () => {
      password.value = null
      passwordConfirm.value = null
    }

    const onSubmit = async () => {
      console.log(password.value, props.id)
      const user = {
        user: props.id,
        password: password.value,
      }
      store.dispatch('app-user/resetPassword', user)
        .then(() => {})
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData)

    return {
      resetData,
      onSubmit,
      password,
      passwordConfirm,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
