import userService from '@core/services/users/userService'
import kanbanService from '@core/services/kanban/kanbanService'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers() {
      return new Promise((resolve, reject) => {
        userService
          .getUsers()
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        userService
          .getUser(id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        userService
          .deleteUser(id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    resetPassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        userService
          .resetPassword(userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        userService
          .registerUser(userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        userService
          .updateUser(userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    assignUser(ctx, assignation) {
      return new Promise((resolve, reject) => {
        userService
          .assignUser(assignation)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createKanban(ctx, kanban) {
      return new Promise((resolve, reject) => {
        kanbanService
          .createKanban(kanban)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
